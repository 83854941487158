//./context/AuthContext
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';


// 創建 AuthContext
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // 初始化時檢查 token 並設置使用者狀態
  useEffect(() => {
    const initializeAuth = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = urlParams.get('token');

      if (tokenFromUrl) {
        localStorage.setItem('token', tokenFromUrl);
       // console.log('AuthContextToken saved to localStorage:', tokenFromUrl);
      }

      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decoded = jwtDecode(token);
          console.log('authcontex',decoded)
          setIsAuthenticated(true);
          setUser({
            e_id: decoded.id.e_id,
            e_name: decoded.id.e_name,
            e_email: decoded.id.e_email,
            e_mobile: decoded.id.e_mobile,
            e_position: decoded.id.e_position,
            e_ssid: decoded.id.e_ssid,
            loginType: decoded.id.loginType,
          });
        } catch (error) {
          console.error('Token decode failed:', error);
          handleLogout();
        }
      }
    };
    initializeAuth();
  }, []);

  const handleLogin = (token, userData) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(userData));
    setIsAuthenticated(true);
    setUser(userData);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUser(null);
    navigate('/login'); // 使用 navigate 來進行路由導航
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

// 自定義 Hook，用來方便地使用 AuthContext
export const useAuth = () => useContext(AuthContext);
