import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Form } from 'react-bootstrap';

const ExperienceTable = () => {
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: 'p_id', direction: 'asc' });
  const observer = useRef();
  const lastExperienceRef = useRef();

  useEffect(() => {
    fetchExperiences(page, sortConfig.key, sortConfig.direction, filter);
  }, [page, filter, sortConfig]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setExperiences([]);
    setPage(1);
    fetchExperiences(1, key, direction, filter);
  };

  const fetchExperiences = async (page, sortKey = 'p_id', sortDirection = 'asc', filter = '') => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/experience', {
        params: { filter, page, sortKey, sortDirection, limit: 10 },
        headers: { 'Authorization': `Bearer ${token}` }
      });
  
      // 更新數據
      setExperiences((prevExperiences) => [...prevExperiences, ...response.data.experiences]);
  
      // 根據返回的數據量來判斷是否還有更多數據
      setHasMore(response.data.experiences.length === 10);
    } catch (error) {
      console.error('Error fetching experiences:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    });

    if (lastExperienceRef.current) {
      observer.current.observe(lastExperienceRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loading, hasMore]);

  return (
    <div className="container-fluid mt-4">
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋經歷資料..."
          value={filter}
          onChange={(e) => {
            setExperiences([]);
            setPage(1);
            setFilter(e.target.value);
          }}
        />
      </div>

      <Table striped bordered hover variant="dark" className="table-striped table-dark">
        <thead>
          <tr>
            <th onClick={() => handleSort('p_id')}>編號</th>
            <th onClick={() => handleSort('StoreName')}>社別名稱</th>
            <th onClick={() => handleSort('p_edit_day')}>歸檔日期</th>
            <th onClick={() => handleSort('p_arriveat')}>屆別</th>
            <th onClick={() => handleSort('PositionName')}>職位名稱</th>
            <th onClick={() => handleSort('EmployeeName')}>姓名</th>
            <th onClick={() => handleSort('p_set')}>組別</th>
            <th onClick={() => handleSort('p_set_position')}>組別職稱</th>
          </tr>
        </thead>
        <tbody>
          {experiences.map((experience, index) => (
            <tr
              ref={index === experiences.length - 1 ? lastExperienceRef : null}
              key={experience.p_id}
            >
              <td>{experience.p_id}</td>
              <td>{experience.StoreName}</td>
              <td>{experience.p_edit_day}</td>
              <td>{experience.p_arriveat}</td>
              <td>{experience.PositionName}</td>
              <td>{experience.EmployeeName}</td>
              <td>{experience.p_set}</td>
              <td>{experience.p_set_position}</td>
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan="8" className="text-center">
                加載中...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!hasMore && (
        <div className="text-center">
          <p>沒有更多數據</p>
        </div>
      )}
    </div>
  );
};

export default ExperienceTable;
