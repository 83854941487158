import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { hasPermission } from './utils/permissions';

const ProtectedRoute = ({ children, permission }) => {
  const { isAuthenticated, user } = useAuth();

  // 如果未登入，重定向到登入頁面
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // 如果設置了權限限制，且用戶不具備該權限，重定向到未授權頁面
  if (permission && !hasPermission(user, permission)) {
    return <Navigate to="/unauthorized" />;
  }

  // 如果通過了驗證和權限檢查，渲染子組件
  return children;
};

export default ProtectedRoute;
