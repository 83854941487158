import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Table, Form, Alert } from 'react-bootstrap';
import { useAuth } from './context/AuthContext'; // 假設你的 AuthContext 位於這個路徑


function SqltableorderTable() {
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const [message, setMessage] = useState('');
  const inputRefs = useRef({}); // 儲存所有輸入框的 ref
  const token = localStorage.getItem('token');

  // 取得所有表格設定資料（支援篩選）
  useEffect(() => {
    fetchData();
  }, [filter, sortConfig]);

  // 取得資料的函式
  const fetchData = () => {
    axios
      .get('/api/sqltableorder', {
        params: {
          filter,
          sortKey: sortConfig.key,
          sortDirection: sortConfig.direction
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => setTableData(response.data))
      .catch((error) => console.error('Error fetching data:', error));
  };

  // 儲存更改資料
  const handleSave = (id) => {
    const rowData = tableData.find((row) => row.id === id);
    axios
      .put(`/api/sqltableorder/${id}`, rowData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(() => {
        setMessage('更新成功！');
        setTableData((prevData) =>
          prevData.map((row) =>
            row.id === id ? { ...row, hasChanges: false, saveStatus: 'saved' } : row
          )
        );
        setTimeout(() => setMessage(''), 2000); // 2 秒後自動隱藏訊息
      })
      .catch((error) => console.error('Error updating data:', error));
  };

  // 更新輸入框內容（文字、數字）
  const handleInputChange = (e, id, field) => {
    const { value } = e.target;
    const updatedData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value, hasChanges: true, saveStatus: 'unsaved' };
      }
      return row;
    });
    setTableData(updatedData);
  };

  // 更新 checkbox 狀態
  const handleCheckboxChange = (e, id, field) => {
    const { checked } = e.target;
    const updatedData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: checked ? 1 : 0, hasChanges: true, saveStatus: 'unsaved' };
      }
      return row;
    });
    setTableData(updatedData);
  };

  // 處理排序邏輯
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // 處理鍵盤事件
  const handleKeyDown = (e, rowIndex, field) => {
    const totalRows = tableData.length;
    if (e.key === 'ArrowDown' || e.key === 'Tab') {
      e.preventDefault();
      const nextRow = rowIndex + 1; // 下一行
      if (nextRow < totalRows) {
        const nextInput = inputRefs.current[`${nextRow}-${field}`];
        if (nextInput) {
          nextInput.focus(); // 將焦點移到下一列的相同欄位
        }
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prevRow = rowIndex - 1; // 上一行
      if (prevRow >= 0) {
        const prevInput = inputRefs.current[`${prevRow}-${field}`];
        if (prevInput) {
          prevInput.focus(); // 將焦點移到上一列的相同欄位
        }
      }
    }
  };

  // 渲染排序圖示
  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' ↑' : ' ↓';
    }
    return '';
  };

  return (
    <div className="container-fluid mt-4">
      {/* 篩選與標題控制 */}
      <div className="d-flex justify-content-between mb-4">
        
        <Form.Control
          type="text"
          placeholder="搜尋表格名稱或中文標題..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{ padding: '10px', width: '100%' }}
        />
      </div>

      {/* 儲存成功訊息顯示 */}
      {message && (
        <Alert variant="success" onClose={() => setMessage('')} dismissible>
          {message}
        </Alert>
      )}

      {/* 資料表格 */}
      <div className="table-responsive">
        <Table striped bordered hover variant="dark" className="table-striped table-dark">
          <thead>
            <tr>
              <th onClick={() => handleSort('id')}>ID {renderSortIcon('id')}</th>
              <th onClick={() => handleSort('table_name')}>Table Name {renderSortIcon('table_name')}</th>
              <th onClick={() => handleSort('table_title_eng')}>Title (ENG) {renderSortIcon('table_title_eng')}</th>
              <th onClick={() => handleSort('table_title_cht')}>Title (CHT) {renderSortIcon('table_title_cht')}</th>
              <th onClick={() => handleSort('table_width')}>Width {renderSortIcon('table_width')}</th>
              <th onClick={() => handleSort('table_order')}>Order {renderSortIcon('table_order')}</th>
              <th>View</th>
              <th>Whole Edit</th>
              <th>Outexl</th>
              <th>View 1</th>
              <th>Whole Edit 1</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={row.id}>
                <td>{row.id}</td>
                <td>
                  <input
                    type="text"
                    value={row.table_name}
                    ref={(el) => (inputRefs.current[`${rowIndex}-table_name`] = el)}
                    onChange={(e) => handleInputChange(e, row.id, 'table_name')}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'table_name')}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.table_title_eng}
                    ref={(el) => (inputRefs.current[`${rowIndex}-table_title_eng`] = el)}
                    onChange={(e) => handleInputChange(e, row.id, 'table_title_eng')}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'table_title_eng')}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.table_title_cht}
                    ref={(el) => (inputRefs.current[`${rowIndex}-table_title_cht`] = el)}
                    onChange={(e) => handleInputChange(e, row.id, 'table_title_cht')}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'table_title_cht')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={row.table_width}
                    ref={(el) => (inputRefs.current[`${rowIndex}-table_width`] = el)}
                    onChange={(e) => handleInputChange(e, row.id, 'table_width')}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'table_width')}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.table_order}
                    ref={(el) => (inputRefs.current[`${rowIndex}-table_order`] = el)}
                    onChange={(e) => handleInputChange(e, row.id, 'table_order')}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'table_order')}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={!!row.table_view}
                    ref={(el) => (inputRefs.current[`${rowIndex}-table_view`] = el)}
                    onChange={(e) => handleCheckboxChange(e, row.id, 'table_view')}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'table_view')}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={!!row.table_whole_edit}
                    ref={(el) => (inputRefs.current[`${rowIndex}-table_whole_edit`] = el)}
                    onChange={(e) => handleCheckboxChange(e, row.id, 'table_whole_edit')}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'table_whole_edit')}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={!!row.table_outexl}
                    ref={(el) => (inputRefs.current[`${rowIndex}-table_outexl`] = el)}
                    onChange={(e) => handleCheckboxChange(e, row.id, 'table_outexl')}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'table_outexl')}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={!!row.table_view_1}
                    ref={(el) => (inputRefs.current[`${rowIndex}-table_view_1`] = el)}
                    onChange={(e) => handleCheckboxChange(e, row.id, 'table_view_1')}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'table_view_1')}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={!!row.table_whole_edit_1}
                    ref={(el) => (inputRefs.current[`${rowIndex}-table_whole_edit_1`] = el)}
                    onChange={(e) => handleCheckboxChange(e, row.id, 'table_whole_edit_1')}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 'table_whole_edit_1')}
                  />
                </td>
                <td>
                  <button
                    style={{
                      backgroundColor: row.saveStatus === 'saved' ? 'green' : row.hasChanges ? 'red' : 'gray',
                      color: 'white'
                    }}
                    onClick={() => handleSave(row.id)}
                  >
                    儲存
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default SqltableorderTable;
