import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Form } from 'react-bootstrap';

const TopicsTable = () => {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: 't_id', direction: 'asc' });
  const observer = useRef();
  const lastTopicRef = useRef();

  // 查詢所有主題資料
  const fetchTopics = async (page, sortKey = 't_id', sortDirection = 'asc', filter = '') => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/topics', {
        params: { filter, page, sortKey, sortDirection },
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setTopics((prevTopics) => [...prevTopics, ...response.data]);
      setHasMore(response.data.length > 0);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
    setLoading(false);
  };

  // 初始化時加載數據
  useEffect(() => {
    fetchTopics(page, sortConfig.key, sortConfig.direction, filter);
  }, [page, filter, sortConfig]);

  // 排序功能
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setTopics([]);
    setPage(1);
    fetchTopics(1, key, direction, filter);
  };

  // 過濾功能
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setTopics([]);
    setPage(1);
    fetchTopics(1, sortConfig.key, sortConfig.direction, e.target.value);
  };

  // 無限滾動邏輯
  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1); // 滾動到底部後自動加載更多
      }
    });

    if (lastTopicRef.current) {
      observer.current.observe(lastTopicRef.current);
    }
  }, [loading, hasMore]);

  return (
    <div className="container-fluid mt-4">
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋主題資料..."
          value={filter}
          onChange={handleFilterChange}
        />
      </div>

      <Table striped bordered hover variant="dark" className="table-striped table-dark">
        <thead>
          <tr>
            <th onClick={() => handleSort('t_id')}>編號</th>
            <th onClick={() => handleSort('store_name')}>社別</th>
            <th onClick={() => handleSort('t_name')}>主題</th>
            <th onClick={() => handleSort('lecturer_name')}>講師</th>
            <th onClick={() => handleSort('employee_name')}>建檔者</th>
            <th onClick={() => handleSort('t_date')}>日期</th>
            <th onClick={() => handleSort('t_edit_day')}>建檔日</th>
            <th onClick={() => handleSort('t_remark')}>備註</th>
          </tr>
        </thead>
        <tbody>
          {topics.map((topic, index) => (
            <tr key={topic.t_id} ref={topics.length === index + 1 ? lastTopicRef : null}>
              <td>{topic.t_id}</td>
              <td>{topic.store_name}</td>
              <td>{topic.t_name}</td>
              <td>{topic.lecturer_name}</td> 
              <td>{topic.employee_name}</td>
              <td>{topic.t_date}</td>
              <td>{topic.t_edit_day}</td>
              <td>{topic.t_remark}</td>
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan="8" className="text-center">
                加載中...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TopicsTable;
