import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Table, Button, Form, Modal } from "react-bootstrap";
import { useAuth } from "./context/AuthContext"; // 假設你的 AuthContext 位於這個路徑
import { hasPermission } from "./utils/permissions"; // 假設你的權限檢查函數位於 utils 目錄中
import DatePicker from "react-datepicker"; // 引入日期選擇器

import "react-bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css";
import "react-datepicker/dist/react-datepicker.css"; // 匯入日期選擇器的CSS樣式




const ActivitiesTable = () => {
  const [activities, setActivities] = useState([]);
  const [mainActs, setMainActs] = useState([]); // 活動類別的資料
  const [stores, setStores] = useState([]); // 社別的資料
  const [employees, setEmployees] = useState([]); // 用於存放員工資料
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(""); // 用於篩選活動
  const [page, setPage] = useState(1); // 分頁數
  const [hasMore, setHasMore] = useState(true); // 判斷是否還有更多數據
  const [sortConfig, setSortConfig] = useState({
    key: "a_id",
    direction: "desc",
  }); // 排序設定
  const observer = useRef(); // 監聽滾動的 observer
  const lastActivityRef = useRef(); // 滾動到底部時的元素參考
  const { user } = useAuth(); // 取得使用者資訊
  const [showModal, setShowModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const token = localStorage.getItem("token");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedCompanyData, setSelectedCompanyData] = useState([]);

  const [lecturers, setLecturers] = useState([]); // 用於存放講師資料
  const [companys, setCompanys] = useState([]); // 用於存放講師資料
  const [formData, setFormData] = useState({
    a_group: "",
    a_ssid: "",
    a_name: "",
    a_no: "",
    a_date: "",
    a_people: "",
    a_location: "",
    a_remark: "",
  });

  // 加載活動
  const fetchActivities = async (
    page,
    sortKey = "a_id",
    sortDirection = "desc",
    filter = ""
  ) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/activities", {
        params: { filter, sortKey, sortDirection, page }, // 傳遞分頁、篩選和排序參數
        headers: { Authorization: `Bearer ${token}` },
      });
      setActivities((prevActivities) => [...prevActivities, ...response.data]);
      setHasMore(response.data.length > 0); // 判斷是否還有更多數據
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
    setLoading(false);
  };

  const fetchAdditionalData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      // 獲取活動類別的資料
      const mainactResponse = await axios.get("/api/mainacts", {
        headers: { Authorization: `Bearer ${token}` },
      });

      // 獲取社別的資料
      const storeResponse = await axios.get("/api/stores", {
        headers: { Authorization: `Bearer ${token}` },
      });

      // 獲取員工資料
      const employeeResponse = await axios.get("/api/employees", {
        headers: { Authorization: `Bearer ${token}` },
        params: { limit: 20 }, // 將 limit 作為查詢參數傳遞
      });

      const lecturersResponse = await axios.get('/api/lecturers', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const companysResponse = await axios.get('/api/companies', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const fetchTopics = async (a_id) => {
  try {
    const response = await axios.get(`/api/topics/${a_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    // 將返回的 topics 資料設置到狀態中
    setFormData({
      ...formData,
      lecturers: response.data.map((topic) => topic.t_lid), // 顯示與活動相關的講師
      topics: response.data.map((topic) => ({
        t_id: topic.t_id,
        t_lid: topic.t_lid,
        t_name: topic.t_name, // 講座主題
      })),
    });
  } catch (error) {
    console.error("Error fetching topics data:", error);
  }
};

      //console.log("Employees data:", employeeResponse.data,data); // 檢查 API 返回的數據

      setMainActs(mainactResponse.data);
      setStores(storeResponse.data);
      setEmployees(employeeResponse.data.data); // 確保設置的是數組
      setLecturers(lecturersResponse.data); // 設置講師資料
      setCompanys(companysResponse.data); // 設置工廠資料
      setLoading(false);
    } catch (error) {
      console.error("Error fetching additional data:", error);
    }
  };

  useEffect(() => {
    fetchActivities(page, sortConfig.key, sortConfig.direction, filter); // 加載活動資料
    fetchAdditionalData(); // 調用 fetchAdditionalData 函數來加載活動類別和社別
  }, [filter, sortConfig, page]); // 監聽 page, filter 和 sortConfig 狀態的變化

  const handleDelete = async (id) => {
    if (window.confirm("確定要刪除這條活動嗎？")) {
      try {
        const response = await axios.delete(`/api/activities/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setActivities((prevActivities) =>
          prevActivities.filter((a) => a.a_id !== id)
        );
        setSuccessMessage(response.data.message);
        setTimeout(() => setSuccessMessage(""), 3000); // 3 秒後清除成功消息
        fetchActivities(currentPage);
      } catch (error) {
        console.error("Error deleting activity:", error);
      }
    }
  };

  const handleSave = async () => {
    const cleanedFormData = Object.fromEntries(
      Object.entries({
        ...formData,
        a_ssid: user.e_ssid, // 使用登录用户的 e_ssid
        a_edit_uid: user.e_id, // 使用当前登录用户的 e_id 作为 a_edit_uid
        a_edit_day: new Date().toISOString().slice(0, 19).replace("T", " "),
        a_date: new Date().toISOString().slice(0, 19).replace("T", " "), // 預設為當前日期
        a_Sdate: new Date().toISOString().slice(0, 19).replace("T", " "), // 預設為當前日期
        a_Edate: new Date().toISOString().slice(0, 19).replace("T", " "), // 預設為當前日期
        a_date_over: new Date().toISOString().slice(0, 19).replace("T", " "), // 預設為當前日期
      }).map(([key, value]) => [key, value === "" ? null : value]) // 将空值转换为 null
    );

    try {
      const token = localStorage.getItem("token");

      if (selectedActivity) {
        // 更新活動
        const response = await axios.put(
          `/api/activities/${selectedActivity.a_id}`,
          cleanedFormData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // 更新前端活動列表
        setActivities((prevActivities) =>
          prevActivities.map((a) =>
            a.a_id === selectedActivity.a_id ? { ...a, ...formData } : a
          )
        );
        setSuccessMessage(response.data.message);
      } else {
        // 新增活動
        const response = await axios.post("/api/activities", cleanedFormData, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // 確保後端返回的活動 ID
        if (response.data.id) {
          setActivities((prevActivities) => [
            ...prevActivities,
            { ...cleanedFormData, a_id: response.data.id }, // 確保新增的活動有 ID
          ]);
        } else {
          console.error("Error: 新增活動失敗，後端未返回活動ID");
        }
        setSuccessMessage(response.data.message); // 顯示新增成功的訊息
      }

      // 成功消息3秒後清除
      setTimeout(() => setSuccessMessage(""), 3000);
      setShowModal(false); // 關閉模態框
    } catch (error) {
      console.error("Error saving activity:", error);

      // 錯誤消息處理
      if (error.response && error.response.data) {
        setErrorMessage(`Error: ${error.response.data.message}`);
      } else {
        setErrorMessage("Error: 無法保存活動，請稍後再試");
      }

      // 3秒後清除錯誤消息
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };

  const openModal = (activity = null) => {
    setSelectedActivity(activity);
    if (activity) {
      // 如果是編輯模式，填充已經有的數據
      setFormData({
        a_group: activity.a_group || "",
        a_ssid: activity.a_ssid || "",
        a_name: activity.a_name || "",
        a_no: activity.a_no || "",
        a_date: activity.a_date || "",
        a_people: activity.a_people || "",
        a_location: activity.a_location || "",
        a_remark: activity.a_remark || "",
      });
    } else {
      // 如果是新增模式，清空表單
      setFormData({
        a_group: "",
        a_ssid: "",
        a_name: "",
        a_no: "",
        a_date: "",
        a_people: "",
        a_location: "",
        a_remark: "",
        topics: [] // 初始化為空陣列
      });
    }
    setShowModal(true);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setActivities([]); // 重置活動資料
    setPage(1); // 重置頁碼
    fetchActivities(1, key, direction, filter);
  };

  // 過濾功能
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setActivities([]); // 清空活動資料
    setPage(1); // 重置頁碼
    fetchActivities(1, sortConfig.key, sortConfig.direction, e.target.value);
  };

  // 無限滾動邏輯
  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1); // 滾動到底部後自動加載更多
      }
    });

    if (lastActivityRef.current) {
      observer.current.observe(lastActivityRef.current);
    }
  }, [loading, hasMore]);

  const parseDate = (dateStr) => {
    const parsedDate = new Date(dateStr);
    // 檢查是否是有效日期物件
    return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
  };

  const handleDateChange = (date) => {
    // 當日期變更時將其設置回 formData，並轉換為 YYYY-MM-DD HH:mm:ss 文字格式
    const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");
    setFormData({ ...formData, a_date: formattedDate });

  };

   // 打開參訪公司表單，並加載相關數據
const handleDoubleClick = async (a_id) => {
  console.log("Double clicked on activity with ID:", a_id); // 調試
  try {
    const response = await axios.get(`/api/visits/${a_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log("Company data fetched:", response.data); // 調試
    setSelectedCompanyData(response.data); // 設置當前活動的參訪公司數據
    setSelectedActivityId(a_id); // 保存當前活動的ID
    setShowCompanyModal(true); // 打開公司管理表單
  } catch (error) {
    console.error("Error fetching company data:", error);
  }
};

  // 關閉公司管理表單
  const closeCompanyModal = () => {
    setShowCompanyModal(false);
    setSelectedCompanyData([]);
    setSelectedActivityId(null);
  };


  // 新增公司
  const handleAddCompany = async () => {
    const newCompany = prompt("請輸入公司名稱：");
    if (newCompany && selectedActivityId) {
      try {
        const response = await axios.post(
          "/api/visits",
          {
            v_name: newCompany,
            v_aid: selectedActivityId,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSelectedCompanyData([...selectedCompanyData, response.data]);
      } catch (error) {
        console.error("Error adding company:", error);
      }
    }
  };

  // 刪除公司
  const handleDeleteCompany = async (v_id) => {
    if (window.confirm("確定要刪除此公司嗎？")) {
      try {
        await axios.delete(`/api/visits/${v_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSelectedCompanyData((prevData) =>
          prevData.filter((company) => company.v_id !== v_id)
        );
      } catch (error) {
        console.error("Error deleting company:", error);
      }
    }
  };

  return (
    <div className="container-fluid mt-4">
      {/* 顯示成功消息 */}
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋活動名稱..."
          value={filter}
          onChange={handleFilterChange}
        />
        {hasPermission(user, "EDIT_ROLE") && (
          <Button variant="success" onClick={() => openModal()}>
            新增活動
          </Button>
        )}
      </div>

      <Table
        striped
        bordered
        hover
        variant="dark"
        className="table-striped table-dark"
      >
        <thead>
          <tr>
            <th onClick={() => handleSort("a_id")}>編號</th>
            <th onClick={() => handleSort("ss_name")}>負責人</th>
            <th onClick={() => handleSort("e_name")}>社別</th>
            <th onClick={() => handleSort("am_name")}>活動類別</th>
            <th onClick={() => handleSort("a_name")}>活動名稱</th>
            <th onClick={() => handleSort("a_date")}>活動日期</th>
            <th onClick={() => handleSort("lecturer_topics")}>講師主題</th>
            <th onClick={() => handleSort("company_names")}>參訪公司</th>
            <th onClick={() => handleSort("a_people")}>人數限制</th>
            <th onClick={() => handleSort("a_location")}>地點</th>
            {hasPermission(user, "EDIT_ROLE") && <th>操作</th>}
          </tr>
        </thead>
        <tbody>
          {activities.map((activity) => (
            <tr key={activity.a_id}>
              {" "}
              {/* 使用 a_id 作为 key */}
              <td>{activity.a_id}</td>
              <td>{activity.employee_name}</td>
              <td>{activity.store_name}</td>
              <td>{activity.mainact_name}</td>
              <td>{activity.a_name}</td>
              <td>{activity.a_date}</td>
              <td>{activity.lecturer_topics}</td>
               {/* 雙擊參訪公司單元格來編輯參訪公司 */}
               <td onDoubleClick={() => handleDoubleClick(activity.a_id)}>
                {activity.company_names || "無"}
              </td>
              <td>{activity.a_people}</td>
              <td>{activity.a_location}</td>
              {hasPermission(user, "EDIT_ROLE") && (
                <td>
                  <Button variant="info" onClick={() => openModal(activity)}>
                    編輯
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(activity.a_id)}
                  >
                    刪除
                  </Button>
                </td>
              )}
            </tr>
          ))}

          {loading && (
            <tr>
              <td colSpan="9" className="text-center">
                加載中...
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedActivity ? "編輯活動" : "新增活動"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>負責人</Form.Label>
              <Form.Control
                as="select"
                value={formData.a_eid}
                onChange={(e) =>
                  setFormData({ ...formData, a_eid: e.target.value })
                }
              >
                <option value="">選擇負責人</option>
                {Array.isArray(employees) ? (
                  employees.map((employee) => (
                    <option key={employee.e_id} value={employee.e_id}>
                      {employee.e_name}
                    </option>
                  ))
                ) : (
                  <option disabled>無可選負責人</option>
                )}
              </Form.Control>
            </Form.Group>
            {/* 社別下拉選單 */}
            <Form.Group className="mb-3">
              <Form.Label>社別</Form.Label>
              <Form.Control
                as="select"
                value={user.e_ssid} // 使用登入者的 e_ssid
                disabled // 禁用選項
              >
                <option key={user.e_ssid} value={user.e_ssid}>
                  {stores.find((store) => store.ss_id === user.e_ssid)
                    ?.ss_name || "未知社別"}
                </option>
              </Form.Control>
            </Form.Group>

            {/* 活動類別下拉選單 */}
            <Form.Group className="mb-3">
              <Form.Label>活動類別</Form.Label>
              <Form.Control
                as="select"
                value={formData.a_group}
                onChange={(e) =>
                  setFormData({ ...formData, a_group: e.target.value })
                }
              >
                <option value="">選擇活動類別</option>
                {mainActs.map((act) => (
                  <option key={act.am_id} value={act.am_id}>
                    {act.am_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>活動名稱</Form.Label>
              <Form.Control
                type="text"
                value={formData.a_name}
                onChange={(e) =>
                  setFormData({ ...formData, a_name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>活動編號</Form.Label>
              <Form.Control
                type="text"
                value={formData.a_no}
                onChange={(e) =>
                  setFormData({ ...formData, a_no: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>報名開始日期</Form.Label>
              <DatePicker
                selected={parseDate(formData.a_date)} // 將文字格式的日期轉換為 Date 物件
                onChange={handleDateChange} // 日期改變時觸發
                dateFormat="yyyy-MM-dd HH:mm:ss" // 顯示日期格式
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="時間"
                className="form-control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>活動開始日期</Form.Label>
              <DatePicker
                selected={parseDate(formData.a_Sdate)} // 將文字格式的日期轉換為 Date 物件
                onChange={handleDateChange} // 日期改變時觸發
                dateFormat="yyyy-MM-dd HH:mm:ss" // 顯示日期格式
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="時間"
                className="form-control"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>活動結束日期</Form.Label>
              <DatePicker
                selected={parseDate(formData.a_Edate)} // 將文字格式的日期轉換為 Date 物件
                onChange={handleDateChange} // 日期改變時觸發
                dateFormat="yyyy-MM-dd HH:mm:ss" // 顯示日期格式
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="時間"
                className="form-control"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>報名結束日期</Form.Label>
              <DatePicker
                selected={parseDate(formData.a_date_over)} // 將文字格式的日期轉換為 Date 物件
                onChange={handleDateChange} // 日期改變時觸發
                dateFormat="yyyy-MM-dd HH:mm:ss" // 顯示日期格式
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="時間"
                className="form-control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>人數</Form.Label>
              <Form.Control
                type="text"
                value={formData.a_people}
                onChange={(e) =>
                  setFormData({ ...formData, a_people: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>地點</Form.Label>
              <Form.Control
                type="text"
                value={formData.a_location}
                onChange={(e) =>
                  setFormData({ ...formData, a_location: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>報名身份限制</Form.Label>
              <Form.Control
                as="select"
                value={formData.a_partake || 9} // 預設值為 9
                onChange={(e) =>
                  setFormData({ ...formData, a_partake: e.target.value })
                }
              >
                <option value="3">理監事</option>
                <option value="4">社友</option>
                <option value="5">新社友</option>
                <option value="6">社友家人</option>
                <option value="7">隊友</option>
                <option value="8">隊友家人</option>
                <option value="9">不限</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>是否鎖定異動</Form.Label>
              <Form.Control
                as="select"
                value={formData.a_stop || 0} // 預設值為 0
                onChange={(e) =>
                  setFormData({ ...formData, a_stop: e.target.value })
                }
              >
                <option value="0">否</option>
                <option value="1">是</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>預算</Form.Label>
              <Form.Control
                type="text"
                value={formData.a_budget}
                onChange={(e) =>
                  setFormData({ ...formData, a_location: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>備註</Form.Label>
              <Form.Control
                as="textarea"
                value={formData.a_remark}
                onChange={(e) =>
                  setFormData({ ...formData, a_remark: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            取消
          </Button>
          <Button variant="primary" onClick={handleSave}>
            儲存
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActivitiesTable;
