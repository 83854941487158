//services/getUserIP.js
const axios = require('axios');

const getUserIP = async () => {
  try {
    const response = await axios.get('https://ipinfo.io/json?token=0dd5359a45e619');
    return response.data;
  } catch (error) {
    console.error('Error fetching IP info:', error);
    return 'Unable to fetch IP info';
  }
};

module.exports = getUserIP;
