import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Personal.css'; // 引入自定義樣式表來控制表單布局
import { useAuth } from '../context/AuthContext';


function Personal() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({});
  const [tableSettings, setTableSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const e_id=user.e_id;
    
    
 
  const token = localStorage.getItem('token');
  // 初始化時讀取社友資料和表格設定
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        // 查詢社友資料
        const employeeResponse = await axios.get(`/api/employees/${e_id}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setEmployee(employeeResponse.data);

        // 查詢表格設定
        const settingsResponse = await axios.get('/api/employee-table-settings', {
          params: { table_name: 'employees' },
          headers: { 'Authorization': `Bearer ${token}` }
         });
        setTableSettings(settingsResponse.data);

        setLoading(false);
      } catch (error) {
        console.error('讀取資料失敗:', error);
        setLoading(false);
      }
    };

    fetchEmployeeData();
  }, [e_id]);

  // 處理表單變更
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({ ...prevEmployee, [name]: value }));
  };

  // 特殊處理 e_foodType 欄位的值變更
  const handleFoodTypeChange = (e) => {
    const value = e.target.value === '葷食' ? 1 : 2; // 將選擇的文字轉換為對應數值
    setEmployee((prevEmployee) => ({ ...prevEmployee, e_foodType: value }));
  };

  // 提交表單更新資料
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`/api/employees/${e_id}`, employee, {
      headers: {
        Authorization: `Bearer ${token}`, // 将 token 作为 Authorization header 附加
      }
    })
      .then(() => {
        alert('資料更新成功');
        navigate('/');
      })
      .catch(error => {
        console.error('更新失敗:', error);
        alert('更新失敗，請重試');
      });
  };

  if (loading) {
    return <div>資料載入中...</div>;
  }

  // 設定照片的 URL，如果沒有 e_photo 就顯示預設照片
  const photoUrl = employee.e_photo 
    ? `https://www.oneimc.com/admin/upload/employees/${employee.e_photo}` 
    : 'https://www.oneimc.com/admin/upload/employees/default.jpg'; // 預設照片

  return (
    <div className="container mt-4 personal-container">
      <h2 className="text-center">個人資料管理</h2>
      <form onSubmit={handleSubmit} className="personal-form">
        {/* 顯示社友照片 */}
        <div className="form-group text-center">
          <img 
            src={photoUrl} 
            alt={employee.e_name || '預設照片'} 
            style={{ width: '100px', height: '120px', borderRadius: '8px', marginBottom: '10px' }} 
          />
        </div>
        {/* 顯示社別和職稱 */}
        <div className="form-group" style={{ width: '100%' }}>
          <label>社別 (Store Name)</label>
          <input
            type="text"
            className="form-control"
            value={employee.store_name || ''} // 顯示社別
            readOnly
            disabled={1}
          />
        </div>

        <div className="form-group" style={{ width: '100%' }}>
          <label>社團職稱 (Position Title)</label>
          <input
            type="text"
            className="form-control"
            value={employee.position_title || ''} // 顯示職稱
            readOnly
            disabled={1}
          />
        </div>

        {/* 顯示 e_foodType 欄位 */}
        <div className="form-group" style={{ width: '100%' }}>
          <label>飲食習慣</label>
          <select
            className="form-control"
            value={employee.e_foodType === 1 ? '葷食' : '素食'}
            onChange={handleFoodTypeChange}
            disabled={employee.table_whole_edit !== 0} // 根據設定來決定是否可編輯
          >
            <option value="葷食">葷食</option>
            <option value="素食">素食</option>
          </select>
        </div>

        {/* 顯示其他可編輯欄位 */}
        {tableSettings.map((setting) => {
          const { table_title_eng, table_title_cht, table_width, table_view, table_whole_edit } = setting;

          // 確認 table_view 為 0 時顯示，並使用 table_whole_edit 來控制編輯權限
          return table_view === 0 ? (
            <div className="form-group" key={table_title_eng} style={{ width: `${table_width}px` }}>
              <label>{table_title_cht}</label>
              <input
                type="text"
                className="form-control"
                name={table_title_eng}
                value={employee[table_title_eng] || ''}
                onChange={handleChange}
                style={{ width: '100%' }}
                disabled={table_whole_edit !== 0} // 如果 table_whole_edit 不是 0，則設置為不可編輯
              />
            </div>
          ) : null;
        })}

        {/* 提交按鈕 */}
        <div className="form-buttons">
          <button type="submit" className="btn btn-success">儲存變更</button>
          <button type="button" className="btn btn-secondary ml-2" onClick={() => navigate(-1)}>返回</button>
        </div>
      </form>
    </div>
  );
}

export default Personal;
