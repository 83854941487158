//permission.js
import jwtDecode from 'jwt-decode'; // 這裡使用 `import jwtDecode from 'jwt-decode';` 而不是 `{ jwtDecode }`，請確認模組導入方式正確

export const hasPermission = (user, permission) => {
  // 如果 `user` 物件存在，直接使用，而不是重新解碼 Token
  //console.log('permisions.js',user);
  if (user) {
    const { loginType, e_ssid, e_position,e_id } = user;
    //console.log('User permissions check - loginType:', loginType, 'e_ssid:', e_ssid, 'e_position:', e_position, 'e_id',e_id);

    // 基於 `loginType` 和 `e_ssid` 來進行權限判斷
    switch (permission) {
      case 'VIEW_MAP':
        return loginType === 1 || loginType === 2; // 所有登入類型的使用者都可以訪問 VIEW_MAP
      case 'VIEW_TOPICS':
        return loginType === 2; // 只有 `loginType` 為 2 的使用者可以訪問
      case 'VIEW_LECTURERS':
        return loginType === 2; // 例如，e_ssid 為 1 或 loginType 為 2 的使用者有權限
      case 'VIEW_COMPANIES':
        return loginType === 2; // 假設 e_ssid 為 18 的使用者不能查看公司資訊
      case 'VIEW_EMPLOYEES':
        return loginType === 2; // 假設 `e_ssid` 為 1 或 `e_position` 為 17 的使用者可以查看社友資訊
      case 'VIEW_EXPERIENCE':
        return loginType === 2; // 假設 e_position 大於等於 10 的使用者可以查看經驗
      case 'VIEW_PARTICIPATIONS':
        return loginType === 2; // 假設 `loginType` 為 3 的使用者可以查看參與記錄
      case 'VIEW_ACTIVITIES':
        return loginType === 2; // 假設 `e_position` 為 5 或 17 的使用者可以查看活動
      case 'VIEW_PERSONAL':
        return loginType === 2; // 如果是 `e_ssid` 為 6 或 `loginType` 為 4 的用戶可以查看個人資訊
      case 'VIEW_SQLTABLEORDER':
        return (e_id === 80 || e_id === 370); // 允許編輯權限 
      case 'VIEW_ALLEMPLOYEE':
        return user.loginType === 2; // 允許編輯權限
      case 'VIEW_EMPLOYEESBATCH':
        return (user.loginType === 2 && e_position === 17) || (e_id === 80 || e_id === 370); // 允許編輯權限 
      case 'EDIT_ROLE':
        return loginType === 2 && ( e_id === 80 || e_id === 370);

      default:
        return false; // 默認不允許訪問
    }
  }

  // 如果 `user` 物件不存在，則嘗試從 localStorage 中獲取 Token 並進行解碼
  const token = localStorage.getItem('token');
  if (!token) {
    return false; // 如果沒有 Token，直接返回 false
  }

  // 解碼 Token，並設置 user 信息
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // 當前時間（秒）

    if (decodedToken.exp < currentTime) {
      // Token 已過期，清除 token 並返回 false
      console.warn('Token 已過期');
      localStorage.removeItem('token'); // 清除過期 token
      window.location.href = '/login'; // 重定向到登入頁面
      return false;
    }
    const { loginType, e_ssid, e_position } = decodedToken.id;
    //console.log('User permissions check - loginType:', loginType, 'e_ssid:', e_ssid, 'e_position:', e_position, 'E_id',e_id);
    // 再次進行權限判斷（基於 Token 的用戶資訊）
    switch (permission) {
      case 'VIEW_MAP':
        return loginType === 1 || loginType === 2; // 所有登入類型的使用者都可以訪問 VIEW_MAP
      case 'VIEW_TOPICS':
        return loginType === 2; // 只有 `loginType` 為 2 的使用者可以訪問
      case 'VIEW_LECTURERS':
        return loginType === 2; // 例如，e_ssid 為 1 或 loginType 為 2 的使用者有權限
      case 'VIEW_COMPANIES':
        return loginType === 2; // 假設 e_ssid 為 18 的使用者不能查看公司資訊
      case 'VIEW_EMPLOYEES':
        return loginType === 2; // 假設 `e_ssid` 為 1 或 `e_position` 為 17 的使用者可以查看社友資訊
      case 'VIEW_EXPERIENCE':
        return loginType === 2; // 假設 e_position 大於等於 10 的使用者可以查看經驗
      case 'VIEW_PARTICIPATIONS':
        return loginType === 2; // 假設 `loginType` 為 3 的使用者可以查看參與記錄
      case 'VIEW_ACTIVITIES':
        return loginType === 2; // 假設 `e_position` 為 5 或 17 的使用者可以查看活動
      case 'VIEW_PERSONAL':
        return loginType === 2; // 如果是 `e_ssid` 為 6 或 `loginType` 為 4 的用戶可以查看個人資訊
      case 'VIEW_SQLTABLEORDER':
        return (e_id === 80 || e_id === 370); // 允許編輯權限 
      case 'VIEW_ALLEMPLOYEE':
        return user.loginType === 2; // 允許編輯權限
      case 'VIEW_EMPLOYEESBATCH':
        return (user.loginType === 2 && e_position === 17) || (e_id === 80 || e_id === 370); // 允許編輯權限 
      case 'EDIT_ROLE':
        return loginType === 2 && ( e_id === 80 || e_id === 370);
      default:
        return false; // 默認不允許訪問
    }
  } catch (error) {
    console.error('Token 解碼錯誤:', error);
    return false;
  }
};
