import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { useAuth } from './context/AuthContext'; // 假設你的 AuthContext 位於這個路徑
import { hasPermission } from './utils/permissions'; // 假設你的權限檢查函數位於 utils 目錄中

const ParticipationsTable = () => {
  const [participations, setParticipations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const lastParticipationRef = useRef();
  const [sortConfig, setSortConfig] = useState({ key: 'workDate', direction: 'desc' });
  const [showModal, setShowModal] = useState(false);
  const [selectedParticipation, setSelectedParticipation] = useState(null);
  const observer = useRef(); // observer 用於監聽滾動事件
  const { user } = useAuth(); // 取得使用者資訊
  const [formData, setFormData] = useState({
    e_usrname: '',
    idcard: '',
    cardNum: '',
    eid: '',
    type: '',
    workDate: '',
    aid: '',
    cardTime: '',
    cardTimeOff: '',
    cardTimemkt: '',
    cardTimemktOff: '',
    cardTimemkt_time: '',
    cardTimemkt_timeOff: '',
    totalTime: '',
    startOntime: '',
    endOntime: '',
    cardParent: '',
    location: '',
    locationENum: '',
    location2: '',
    locationENum2: '',
    status: ''
  });

  const [userPosition] = useState(() => {
    const position = localStorage.getItem('e_position');
    const ssid = localStorage.getItem('e_ssid');
    return {
      position: position ? parseInt(position, 10) : null,
      ssid: ssid ? parseInt(ssid, 10) : null
    };
  });

  useEffect(() => {
    // 初始化數據
    fetchParticipations(1, sortConfig.key, sortConfig.direction, filter);
  }, [filter, sortConfig]);

  useEffect(() => {
    // 滾動監聽邏輯
    if (loading || !hasMore) return;
    if (observer.current) observer.current.disconnect(); // 防止重複觀察

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1); // 滾動到底部後加載下一頁
      }
    });

    if (lastParticipationRef.current) {
      observer.current.observe(lastParticipationRef.current); // 監聽最後一個參考元素
    }
  }, [loading, hasMore]);

  useEffect(() => {
    if (page > 1) {
      fetchParticipations(page, sortConfig.key, sortConfig.direction, filter); // 分頁加載
    }
  }, [page]);

  const fetchParticipations = async (page, sortKey = 'rid', sortDirection = 'asc', filter = '') => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/participations', {
        params: { filter, page, sortKey, sortDirection },
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setParticipations((prevParticipations) => [...prevParticipations, ...response.data.participations]);
      setHasMore(response.data.participations.length > 0); // 確定是否還有更多數據
    } catch (error) {
      console.error('Error fetching participations:', error);
    }
    setLoading(false);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setParticipations([]); // 清空當前數據
    setPage(1); // 重置頁碼
  };

  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除這條記錄嗎？')) {
      try {
        await axios.delete(`/api/participations/${id}`);
        setParticipations((prevParticipations) => prevParticipations.filter((p) => p.rid !== id));
      } catch (error) {
        console.error('Error deleting participation:', error);
      }
    }
  };

  const handleSave = async () => {
    try {
      if (selectedParticipation) {
        await axios.put(`/api/participations/${selectedParticipation.rid}`, formData);
        setParticipations((prevParticipations) =>
          prevParticipations.map((p) =>
            p.rid === selectedParticipation.rid ? formData : p
          )
        );
      } else {
        const response = await axios.post('/api/participations', formData);
        setParticipations((prevParticipations) => [response.data, ...prevParticipations]);
      }
      setShowModal(false);
    } catch (error) {
      console.error('Error saving participation:', error);
    }
  };

  const openModal = (participation = null) => {
    setSelectedParticipation(participation);
    setFormData(participation || {
      e_usrname: '',
      idcard: '',
      cardNum: '',
      eid: '',
      type: '',
      workDate: '',
      aid: '',
      cardTime: '',
      cardTimeOff: '',
      cardTimemkt: '',
      cardTimemktOff: '',
      cardTimemkt_time: '',
      cardTimemkt_timeOff: '',
      totalTime: '',
      startOntime: '',
      endOntime: '',
      cardParent: '',
      location: '',
      locationENum: '',
      location2: '',
      locationENum2: '',
      status: ''
    });
    setShowModal(true);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setParticipations([]); // 清空參與記錄
    setPage(1); // 重置頁碼
  };

  return (
    <div className="container-fluid mt-4">
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋參與記錄..."
          value={filter}
          onChange={(e) => {
            setParticipations([]);
            setPage(1);
            setFilter(e.target.value);
          }}
        />
        {hasPermission(user, 'EDIT_ROLE') && (
          <Button variant="success" size="sm" className="me-2" onClick={() => openModal()}>
            新增
          </Button>
        )}
      </div>

      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th onClick={() => handleSort('rid')}>編號</th>
            <th onClick={() => handleSort('store_name')}>社別</th>
            <th onClick={() => handleSort('e_name')}>姓名</th>
            <th onClick={() => handleSort('a_name')}>活動名稱</th>
            <th onClick={() => handleSort('workDate')}>報名日期</th>
            <th onClick={() => handleSort('cardTime')}>時間記錄</th>
            {hasPermission(user, 'EDIT_ROLE') && (
              <th>操作</th>
            )}
          </tr>
        </thead>
        <tbody>
          {participations.map((participation, index) => {
            return (
              <tr key={participation.rid} ref={participations.length === index + 1 ? lastParticipationRef : null}>
                <td>{participation.rid}</td>
                <td>{participation.store_name}</td>
                <td>{participation.e_name}</td>
                <td>{participation.a_name}</td>
                <td>{participation.workDate}</td>
                <td>{participation.cardTime}</td>
                {hasPermission(user, 'EDIT_ROLE') && (
                  <td>
                    <Button variant="info" size="sm" className="me-2"  onClick={() => openModal(participation)}>編輯</Button>
                    <Button variant="danger" size="sm" className="me-2"  onClick={() => handleDelete(participation.rid)}>刪除</Button>
                  </td>
                )}
              </tr>
            );
          })}
          {loading && (
            <tr>
              <td colSpan="7" className="text-center">加載中...</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedParticipation ? '編輯參與記錄' : '新增參與記錄'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>社友名稱</Form.Label>
              <Form.Control
                type="text"
                value={formData.e_usrname}
                onChange={(e) => setFormData({ ...formData, e_usrname: e.target.value })}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>工作日期</Form.Label>
              <Form.Control
                type="text"
                value={formData.workDate}
                onChange={(e) => setFormData({ ...formData, workDate: e.target.value })}
              />
            </Form.Group>

            {/* 添加其他表單字段 */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>取消</Button>
          <Button variant="primary" onClick={handleSave}>儲存</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ParticipationsTable;
